
import { mapGetters } from "vuex";
// import LanguageSwitcher from "~/components/common/LanguageSwitcher";
import { topMenuFormatter } from "@/utils";
import Footer4Col from "@/components/common/Footer4Col";
export default {
  name: "AppFooter",
  components: {
    // LanguageSwitcher,
    Footer4Col,
  },
  data() {
    return {
      menuData: [],
      isSubscribed: false,
      leftColumnData: null,
      footer4ColumnData: null,
      footerData: null,
      test: ``,
    };
  },
  async fetch() {
    await Promise.allSettled([
      this.getFooterLeftColumn(),
      this.getFooter4Column(),
      this.getFooterData(),
    ]);
  },
  computed: {
    ...mapGetters({
      getLanguages: "general/getLanguages",
      settings: "general/getSettings",
    }),
  },
  created() {
    if (this.$store.state.general?.menu?.footer?.items) {
      this.menuData = topMenuFormatter(
        this.$store.state.general.menu.footer.items
      );
    }
  },
  methods: {
    async getFooterLeftColumn() {
      try {
        const { data } = await this.$api.pages.getBlok("footer-left-column");
        this.leftColumnData = data.data;
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
      }
    },
    async getFooter4Column() {
      try {
        const { data } = await this.$api.pages.getBlok("footer-4-column");
        this.footer4ColumnData = data.data;
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
      }
    },
    async getFooterData() {
      try {
        const { data } = await this.$api.pages.getBlok("footer-data");
        this.footerData = data.data;
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
      }
    },
  },
};
